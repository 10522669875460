import React from 'react';
import {
  Link,
  Paragraph,
  PageWithSubNav,
  PlatformHeader,
  Fixed,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="August 2023"
      subnav="release-notes">
      <div id="August2023" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          <q cite="https://www.azquotes.com/quote/345708?ref=funny-inspirational">
            If you think you are too small to make a difference, try sleeping
            with a mosquito.
          </q>{' '}
          <br />
          <span className="uni-margin--half--left uni-text--small uni-color--subtle">
            - Dalai Lama
          </span>
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              packageName="@hudl/rn-uniform"
              packageLink="https://github.com/hudl/rn-uniform/tree/main/packages/rn-uniform/README.md"
              changelogUrl="https://github.com/hudl/rn-uniform/tree/main/packages/rn-uniform/CHANGELOG.md#4121"
              version="4.12.1"
            />
            <div className={styles.changesList}>
              <Fixed>
                User interactions to left and right of toast messages blocked on
                larger screens. Toasts rendered using{' '}
                <Link href="/components/notifications/toast/code?reactnative#Toast%20Messenger">
                  Toast Messenger
                </Link>{' '}
                will now float right on screens larger than <code>480px</code>.
              </Fixed>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
